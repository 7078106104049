import Theme from "../themes/Theme"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import ToolbarPlugin from "../plugins/ToolbarPlugin"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"
import { ListItemNode, ListNode } from "@lexical/list"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"
import { TRANSFORMERS } from "@lexical/markdown"

import ListMaxIndentLevelPlugin from "../plugins/ListMaxIndentLevelPlugin"
import CodeHighlightPlugin from "../plugins/CodeHighlightPlugin"
import AutoLinkPlugin from "../plugins/AutoLinkPlugin"
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'

import { $getRoot } from 'lexical'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

const editorConfig = {
    // The editor theme
    theme: Theme,
    // Handling of errors during update
    onError(error) {
        throw error
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode
    ]
}

function LoadHtmlPlugin({ html }) {
    const [editor] = useLexicalComposerContext();

    if (html === "") {
        return null
    } else {
        editor.update(() => {
            let nodes = [];

            // Parse html
            const parser = new DOMParser();
            const dom = parser.parseFromString(html, "text/html");
            nodes = $generateNodesFromDOM(editor, dom);

            // Set content
            const root = $getRoot();
            root.clear();
            root.append(...nodes);
        });

        return null
    }

}


export default function Editor(props) {

    const { placeholder, setHTML = () => { }, setTemplateVariables = () => { }, initialHtml = "" } = props

    const onChange = (editorState, editor) => {
        editor.update(() => {
            const rawHTML = $generateHtmlFromNodes(editor, null)
            const editorStateTextString = editorState.read(() => $getRoot().getTextContent())
            setHTML(rawHTML)
            setTemplateVariables(editorStateTextString)
        })
    }

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container">
                <ToolbarPlugin />
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" />}
                        placeholder={<div className="editor-placeholder">{placeholder}</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <CodeHighlightPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <AutoLinkPlugin />
                    <LoadHtmlPlugin html={initialHtml} />
                    <ListMaxIndentLevelPlugin maxDepth={7} />
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                </div>
            </div>
            <OnChangePlugin onChange={onChange} />
        </LexicalComposer>
    )
}
