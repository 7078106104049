import React, { useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { createBlog } from '../actions'
import { getRedirect } from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    FormHelperText,
    Button,
    CircularProgress,
    useColorModeValue,
    Textarea,
    Select,
    Card
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'
import InputFile from "../components/InputFile"
import Editor from "../components/Editor"
import "../styles.css"


const BlogCreate = () => {

    const[html, setHTML] = useState("")
    const [editor, setEditor] = useState(null);
    const dispatch = useDispatch()
    const redirect = useSelector(state => getRedirect(state))
    const color = useColorModeValue('white', 'gray.800')

    if (redirect) {
        return <Redirect to="/blog" />
    }

    return (
        <Layout>
            {redirect && <Redirect to="/blog" />}
            <Box w={'full'} bg={color} rounded={'md'} m={3} p={6} >
                <Formik
                    initialValues={{
                        title: '',
                        description: '',
                        feature: 0,
                        tag: '',
                        image: null
                    }}
                    validate={values => FormValidator([
                        { field: 'Titulo', value: values.title, rules: ['required'] },
                        { field: 'Descripcion', value: values.description, rules: ['required'] },
                        { field: 'Imagen', value: values.image, rules: ['required'] },
                    ])}
                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        setSubmitting(true)
                        const formData = new FormData()
                        for (let value in values) {
                            formData.append(value, values[value])
                        }
                        formData.append('content', html)
                        Promise.resolve(dispatch(createBlog(formData)))
                            .then(() => {
                                setSubmitting(false)
                                resetForm()
                            })

                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>

                            <FormControl isInvalid={errors.title && touched.title}>
                                <FormLabel>Titulo</FormLabel>
                                <Input name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                                <FormErrorMessage>{errors.title}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.description && touched.description}>
                                <FormLabel>Descripción</FormLabel>
                                <Textarea name="description" onChange={handleChange} onBlur={handleBlur} value={values.description} />
                                <FormErrorMessage>{errors.description}</FormErrorMessage>
                                <FormHelperText>Si el contenido es muy largo se recortará</FormHelperText>
                            </FormControl>
                            <br />
                            <FormLabel>Contenido</FormLabel>
                            <Card>
                                <Editor placeholder={"Contenido"} setHTML={setHTML} setEditor={setEditor} />
                            </Card>

                            <FormControl isInvalid={errors.image && touched.image}>
                                <FormLabel>Imagen</FormLabel>
                                <InputFile name={"image"} label={"Imagen"} setFieldValue={setFieldValue} value={values.image} />
                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                <FormHelperText>subir únicamente imágenes JPG/PNG no mayor de 5MB</FormHelperText>
                            </FormControl>
                            <br />
                            <FormControl isInvalid={errors.feature && touched.feature}>
                                <FormLabel>Destacado</FormLabel>
                                <Select name="feature" onChange={handleChange} onBlur={handleBlur} value={values.feature}>
                                    <option value={1} >Destacar</option>
                                    <option value={0} >Normal</option>
                                </Select>
                                <FormErrorMessage>{errors.feature}</FormErrorMessage>
                                <FormHelperText>Al destacar aparecerá como imagen principal del blog</FormHelperText>
                            </FormControl>
                            <br />
                            <FormControl isInvalid={errors.tag && touched.tag}>
                                <FormLabel>Tags</FormLabel>
                                <Input name="tag" onChange={handleChange} onBlur={handleBlur} value={values.tag} />
                                <FormHelperText>Separar por ,</FormHelperText>
                            </FormControl>
                            <br />
                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Layout>
    )
}

export default BlogCreate
