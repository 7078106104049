import React from "react"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { fetchBanners } from '../actions'
import { getBanners } from '../reducers'
import {
    Box,
    Button,
    useColorModeValue
} from '@chakra-ui/react'
import Layout from '../layout'
import DataTable from 'react-data-table-component'


const columns = [
    { name: '#', selector: row => row.id, sortable: true },
    { name: 'Estado', selector: row => row.active, sortable: true },
    { name: 'Banner para blog', selector: row => row.banner_blog, sortable: true },
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
];

class BannerList extends React.Component {

    componentDidMount() {
        this.props.fetchBanners()
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {
        const { banners } = this.props

        return (
            <Layout>
                <Box
                    w={'full'}
                    bg={this.getColor}
                    rounded={'md'}
                    m={3}
                    p={6}
                >
                    <DataTable
                        pagination
                        columns={columns}
                        defaultSortAsc={true}
                        defaultSortFieldId={1}
                        data={banners.map(banner => {
                            return {
                                id: banner.id,
                                active: banner.active ? "Activo" : "Inactivo",
                                banner_blog: banner.banner_blog ? "Activo" : "Inactivo",
                                opcion: <Link to={`/config/banner/${banner.id}/edit`}><Button colorScheme="teal" variant="outline">Ver más</Button></Link>
                            }
                        })}
                    />
                </Box>
                <Link to={`/config/banner/create`}><Button colorScheme="easycar2">Nuevo Banner</Button></Link>
            </Layout >
        )
    }
}

const mapStateToProps = (state) => ({
    banners: getBanners(state)
})

const mapDispatchToProps = { fetchBanners }

export default connect(mapStateToProps, mapDispatchToProps)(BannerList)