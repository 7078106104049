import axios from 'axios'
import {
    FETCH_BLOG,
    APIpath,
    addToast,
    setRedirect
} from '../actions'

const setBlogs = (blogs) => ({
    type: FETCH_BLOG,
    blogs
})

export const fetchBlogs = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/blog/`)
        .then(function (response) {
            dispatch(setBlogs(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchActiveBlogs = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/blog/active-post`)
        .then(function (response) {
            dispatch(setBlogs(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const createBlog = (values) => (dispatch) => {
    axios.post(`${APIpath}/blog/store`, values)
        .then(function (response) {
            dispatch(setRedirect(true))
            dispatch(addToast('success', 'Elemento creado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateBlog = (values) => (dispatch) => {
    axios.post(`${APIpath}/blog/update`, values)
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            window.location.reload(false);
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateBlogImage = (values) => (dispatch) => {

    axios.post(`${APIpath}/blog/update-image`, values)
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            window.location.reload(false);
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateStatusBlog = (id) => dispatch => {
    axios.post(`${APIpath}/blog/status`, { id })
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            dispatch(setRedirect(true))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}