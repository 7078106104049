const blog = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_BLOG': return action.blogs
        default: return state
    }
}

export const getPosts = (state) => state
export const getPost = (state, id) => state.reduce((p, blog) => {
    if ( Number(blog.id) === Number(id)) return blog
    return p
}, {})

export default blog