import React, { useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { createBanner } from '../actions'
import { getRedirect } from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    useColorModeValue,
    Select,
    Card,
    Radio, RadioGroup,
    HStack,
    Image,
} from '@chakra-ui/react'
import { Formik, Field } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'
import InputFile from "../components/InputFile"
import Editor from "../components/Editor"
import "../styles.css"
import design1 from '../multimedia/design1.jpg'
import design2 from '../multimedia/design2.jpg'
import design3 from '../multimedia/design3.jpg'
import design4 from '../multimedia/design4.jpg'
import design5 from '../multimedia/design5.jpg'



const BannerCreate = () => {

    const [html, setHTML] = useState("")
    const [editor, setEditor] = useState(null);
    const dispatch = useDispatch()
    const redirect = useSelector(state => getRedirect(state))
    const color = useColorModeValue('white', 'gray.800')

    if (redirect) {
        return <Redirect to="/config/banner" />
    }

    return (
        <Layout>

            <Box w={'full'} bg={color} rounded={'md'} m={3} p={6} >
                <Formik
                    initialValues={{
                        buttonText: '',
                        buttonUrl: '',
                        design: "1",
                        colorDesign: 1,
                        image: null
                    }}
                    validate={values => FormValidator([
                        { field: 'diseño', value: values.design, rules: ['required'] },
                        { field: 'color diseño', value: values.colorDesign, rules: ['required'] },
                        { field: 'Texto Call to action', value: values.buttonText, rules: ['required'] },
                        { field: 'image', value: values.image, rules: ['required'] },
                    ])}
                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        setSubmitting(true)
                        const formData = new FormData()
                        for (let value in values) {
                            formData.append(value, values[value])
                        }
                        formData.append('text', html)

                        Promise.resolve(dispatch(createBanner(formData)))
                            .then(() => {
                                setSubmitting(false)
                                resetForm()
                            })

                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={errors.image && touched.image}>
                                <FormLabel>Imagen banner</FormLabel>
                                <InputFile name={"image"} label={"Imagen"} setFieldValue={setFieldValue} value={values.image} />
                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                            </FormControl>


                            <FormLabel>Contenido</FormLabel>
                            <Card>
                                <Editor placeholder={"Contenido"} setHTML={setHTML} setEditor={setEditor} />
                            </Card>

                            <br />
                            <FormControl isInvalid={errors.buttonText && touched.buttonText}>
                                <FormLabel>Texto Call to action</FormLabel>
                                <Input name="buttonText" onChange={handleChange} onBlur={handleBlur} value={values.buttonText} />
                                <FormErrorMessage>{errors.buttonText}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.buttonUrl && touched.buttonUrl}>
                                <FormLabel>URL Call to action</FormLabel>
                                <Input name="buttonUrl" onChange={handleChange} onBlur={handleBlur} value={values.buttonUrl} />
                                <FormErrorMessage>{errors.buttonUrl}</FormErrorMessage>
                            </FormControl>
                            <FormControl as='fieldset' isInvalid={errors.design && touched.design}>
                                <FormLabel as='legend'>Diseño</FormLabel>
                                <RadioGroup value={values.design} onChange={handleChange} spacing='24px' name="design">
                                    <HStack spacing='24px'>
                                        <Field colorScheme="easycar1" as={Radio} value={"1"} >
                                            <Image src={design1} width={"100px"} />
                                        </Field>
                                        <Field colorScheme="easycar1" as={Radio} value={"2"} >
                                            <Image src={design2} width={"100px"} />
                                        </Field>
                                        <Field colorScheme="easycar1" as={Radio} value={"3"} >
                                            <Image src={design3} width={"100px"} />
                                        </Field>
                                        <Field colorScheme="easycar1" as={Radio} value={"4"} >
                                            <Image src={design4} width={"100px"} />
                                        </Field>
                                        <Field colorScheme="easycar1" as={Radio} value={"5"} >
                                            <Image src={design5} width={"100px"} />
                                        </Field>
                                    </HStack>
                                </RadioGroup>
                                <FormErrorMessage>{errors.design}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.colorDesign && touched.colorDesign}>
                                <FormLabel>Color Diseño</FormLabel>
                                <Select name="colorDesign" onChange={handleChange} onBlur={handleBlur} value={values.colorDesign}>
                                    <option value={1} > Verde</option>
                                    <option value={2} > Gris</option>
                                    <option value={3} > Blanco</option>
                                </Select>
                                <FormErrorMessage>{errors.colorDesign}</FormErrorMessage>
                            </FormControl>

                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Crear'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Layout>
    )
}

export default BannerCreate
