import axios from 'axios'
import {
    FETCH_BANNER,
    APIpath,
    addToast,
    setRedirect
} from '../actions'

const setBanners = (banners) => ({
    type: FETCH_BANNER,
    banners
})

export const fetchBanners = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/banner/`)
        .then(function (response) {
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchActiveBanners = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/banner/active-banner`)
        .then(function (response) {
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const createBanner = (values) => (dispatch) => {

    console.log(values)
    /*
    axios.post(`${APIpath}/banner/store`, values)
        .then(function (response) {
            dispatch(setRedirect(true))
            dispatch(addToast('success', 'Elemento creado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })

        */
}

export const updateBanner = (values) => (dispatch) => {

    axios.post(`${APIpath}/banner/update`, values)
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateBannerImage = (values) => (dispatch) => {

    axios.post(`${APIpath}/banner/update-image`, values)
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            window.location.reload(false);
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateStatusBanner = (id) => dispatch => {
    axios.post(`${APIpath}/banner/status`, { id })
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            dispatch(setRedirect(true))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateBlogBanner = (id) => dispatch => {
    axios.post(`${APIpath}/banner/status-blog`, { id })
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            dispatch(setRedirect(true))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}