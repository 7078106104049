const banner = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_BANNER': return action.banners
        default: return state
    }
}

export const getBanners = (state) => state
export const getBanner = (state, id) => state.reduce((p, banner) => {
    if ( Number(banner.id) === Number(id)) return banner
    return p
}, {})

export default banner