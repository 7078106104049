import React from "react"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { fetchBlogs } from '../actions'
import { getPosts } from '../reducers'
import {
    Box,
    Button,
    useColorModeValue,
    Tag
} from '@chakra-ui/react'
import Layout from '../layout'
import DataTable from 'react-data-table-component'


const columns = [
    { name: 'Fecha creación', selector: row => row.fecha, sortable: true },
    { name: 'Titulo', selector: row => row.title, sortable: true },
    { name: 'Destacado', selector: row => row.feature, sortable: true },
    { name: 'Tags', selector: row => row.tag, sortable: false },
    { name: 'Estado', selector: row => row.active, sortable: true },
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
];

class BlogList extends React.Component {

    componentDidMount() {
        this.props.fetchBlogs()
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {
        const { posts } = this.props

        return (
            <Layout>
                <Box
                    w={'full'}
                    bg={this.getColor}
                    rounded={'md'}
                    m={3}
                    p={6}
                >
                    <DataTable
                        pagination
                        columns={columns}
                        defaultSortAsc={false}
                        defaultSortFieldId={1}
                        data={posts.map(post => {
                            let time = new Date(post.created_at)
                            return {
                                id: post.id,
                                fecha: time.toLocaleString(),
                                title: post.title,
                                feature: post.feature ? 'Destacado' : 'Normal',
                                active: post.active ? "Activo" : "Inactivo",
                                tag: post.tag !== '' ? post.tag.split(",").map(tagText => <Tag>{tagText}</Tag>) : 'Sin etiquetas',
                                opcion: <Link to={`/blog/${post.id}/edit`}><Button colorScheme="teal" variant="outline">Ver más</Button></Link>
                            }
                        })}
                    />
                </Box>
                <Link to={`/blog/create`}><Button colorScheme="easycar2">Nuevo Post</Button></Link>
            </Layout >
        )
    }
}

const mapStateToProps = (state) => ({
    posts: getPosts(state)
})

const mapDispatchToProps = { fetchBlogs }

export default connect(mapStateToProps, mapDispatchToProps)(BlogList)