import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { updateBlog, updateStatusBlog, APIpath, updateBlogImage } from '../actions'
import { getPost, getRedirect } from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    FormHelperText,
    Button,
    CircularProgress,
    useColorModeValue,
    Textarea,
    Select,
    Image,
    Card
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'
import InputFile from "../components/InputFile"
import placeholder from '../multimedia/placeholder.jpg'
import Editor from "../components/Editor"
import "../styles.css"

const replaceImage = (error) => {
    error.target.src = placeholder;
}

const BlogEdit = () => {

    const [html, setHTML] = useState("")
    const [mounted, setMounted] = useState(false)
    const [editor, setEditor] = useState(null);
    const dispatch = useDispatch()
    const redirect = useSelector(state => getRedirect(state))

    const [post_id] = useState(useParams("id").id)
    const post = useSelector(state => getPost(state, post_id))
    const color = useColorModeValue('white', 'gray.800')

    useEffect(() => {
        setHTML(post.content)
        setMounted(true)
    }, [post])

    if (redirect) {
        return <Redirect to="/blog" />
    }

    return (
        <Layout>

            <Box w={'full'} bg={color} rounded={'md'} m={3} p={6} >

                <h1>Cambio de imagen</h1>
                <hr />
                <br />
                <Formik
                    initialValues={{
                        image: null
                    }}
                    validate={values => FormValidator([
                        { field: 'image', value: values.image, rules: ['required'] },
                    ])}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        const formData = new FormData()
                        for (let value in values) {
                            formData.append(value, values[value])
                        }
                        formData.append('id', post.id)
                        Promise.resolve(dispatch(updateBlogImage(formData, post.id)))
                            .then(() => {
                                setSubmitting(false)
                                resetForm()
                            })
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>

                            <Image src={`${APIpath}/blog/image/${post.id}?${Math.random().toString(36)}`} alt={"preview" + post.id} key={post.id} h={"200px"} w={"auto"} m={"auto"} onError={replaceImage} />

                            <FormControl isInvalid={errors.image && touched.image}>
                                <FormLabel>Imagen</FormLabel>
                                <InputFile name={"image"} label={"Imagen"} setFieldValue={setFieldValue} value={values.image} />
                                <FormErrorMessage>{errors.image}</FormErrorMessage>
                                <FormHelperText>subir únicamente imágenes JPG/PNG no mayor de 5MB</FormHelperText>
                            </FormControl>

                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>

            <Box w={'full'} bg={color} rounded={'md'} m={3} p={6} >
                <h1>Cambio de información</h1>
                <hr />
                <br />
                <Formik
                    initialValues={{
                        title: post.title,
                        description: post.description,
                        feature: post.feature,
                        tag: post.tag
                    }}
                    validate={values => FormValidator([
                        { field: 'Titulo', value: values.title, rules: ['required'] },
                        { field: 'Descripcion', value: values.description, rules: ['required'] }
                    ])}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        const formData = new FormData()
                        for (let value in values) {
                            formData.append(value, values[value])
                        }
                        formData.append('id', post.id)
                        formData.append('content', html)
                        Promise.resolve(dispatch(updateBlog(formData, post.id)))
                            .then(() => {
                                setSubmitting(false)
                            })
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>

                            <FormControl isInvalid={errors.title && touched.title}>
                                <FormLabel>Titulo</FormLabel>
                                <Input name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                                <FormErrorMessage>{errors.title}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.description && touched.description}>
                                <FormLabel>descripción</FormLabel>
                                <Textarea name="description" onChange={handleChange} onBlur={handleBlur} value={values.description} />
                                <FormErrorMessage>{errors.description}</FormErrorMessage>
                                <FormHelperText>Si el contenido es muy largo se recortará</FormHelperText>
                            </FormControl>
                            <br />

                            <FormLabel>Contenido</FormLabel>
                            <Card>
                                {mounted ?
                                    <Editor placeholder={"Contenido"} setHTML={setHTML} setEditor={setEditor} initialHtml={html} />
                                    : <>Loading</>
                                }
                            </Card>

                            <FormControl isInvalid={errors.feature && touched.feature}>
                                <FormLabel>Destacado</FormLabel>
                                <Select name="feature" onChange={handleChange} onBlur={handleBlur} value={values.feature}>
                                    <option value={1} > Destacar</option>
                                    <option value={0} > Normal</option>
                                </Select>
                                <FormErrorMessage>{errors.feature}</FormErrorMessage>
                                <FormHelperText>Al destacar aparecerá como imagen principal del blog</FormHelperText>
                            </FormControl>
                            <br />

                            <FormControl isInvalid={errors.tag && touched.tag}>
                                <FormLabel>Tags</FormLabel>
                                <Input name="tag" onChange={handleChange} onBlur={handleBlur} value={values.tag} />
                                <FormHelperText>Separar por ,</FormHelperText>
                            </FormControl>
                            <br />

                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                            </Button>
                        </form>
                    )}
                </Formik>

            </Box>

            <Box w={'full'} bg={color} rounded={'md'} m={3} p={6} >
                <h1>Estado de post</h1>
                <hr />
                <br />
                <Button onClick={() => {
                    dispatch(updateStatusBlog(post.id))
                }} colorScheme="easycar2" type="submit" mt={4} > {post.active ? "Ocultar" : "Activar"} </Button>
            </Box>
        </Layout >
    )
}

export default BlogEdit
